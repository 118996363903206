// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("@popperjs/core");

require.context("../images", true);
require("../stylesheets");
require("cajour").default.start();

require("trix")
require("@rails/actiontext")

addEventListener("trix-initialize", function() {
  [
    ".trix-button--icon-strike",
    ".trix-button--icon-link",
    ".trix-button--icon-quote",
    ".trix-button--icon-code",
    ".trix-button--icon-decrease-nesting-level",
    ".trix-button--icon-increase-nesting-level",
    ".trix-button-group--file-tools",
    ".trix-button-group--history-tools"
  ].forEach((cls) => {
    document.querySelector(cls).remove();
  });
}, true);

import { findAll, isUnattached, setAttached } from "./dom";

const keyHandler = (event) => {
  if (event.key == "Escape") {
    const form = event.target.closest("form");

    if (form) {
      const cancelLink = form.querySelector(
        "a[data-remote][data-gk-form-cancel]"
      );

      if (cancelLink) {
        cancelLink.click();
      }
    }
  }
};

export default () =>
  findAll(document, "form a[data-remote][data-gk-form-cancel]")
    .map((element) => element.closest("form"))
    .filter((element) => isUnattached("keyboard", element))
    .map((element) => setAttached("keyboard", element))
    .forEach((form) => {
      form.addEventListener("keydown", keyHandler);
    });

import { findAll, isUnattached, setAttached } from "./dom";

export default (turbolinks) => {
  const focusables = findAll(document, "[autofocus]")
    .filter((element) => typeof element.focus == "function")
    .filter((element) => isUnattached("autofocus", element))
    .map((element) => setAttached("autofocus", element));

  if (focusables.length > 0 && !turbolinks) {
    focusables[0].focus();
  }
};

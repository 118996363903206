import { Modal } from "bootstrap";
import { findAll, isUnattached, setAttached } from "./dom";

export default () => {
  findAll(document, ".modal")
    .filter((element) => isUnattached("modal", element))
    .map((element) => setAttached("modal", element))
    .forEach((element) => {
      if (element.classList.contains("gk-viewer-modal")) {
        return;
      }

      const modal = new Modal(element, { show: false });

      const keyHandler = (event) => {
        if (
          event.key == "Escape" &&
          element.getAttribute("data-gk-dismissable") !== "false"
        ) {
          modal.hide();
        }
      };

      element.addEventListener("hidden.bs.modal", function (e) {
        modal.dispose();
        document.removeEventListener("keydown", keyHandler);
        element.remove();
        window.current_modal = null;
      });

      document.addEventListener("keydown", keyHandler);

      modal.show();
      window.current_modal = modal;
    });

  findAll(document, ".gk-meta[data-gk-dismiss-modal]").forEach((element) => {
    element.remove();

    if (window.current_modal) {
      window.current_modal.hide();
    }
  });
};

import { findAll, isUnattached, setAttached } from "./dom";

const toggleTargets = (element) => {
  const selector = element.getAttribute("data-gk-toggle-selector");

  findAll(document, selector).forEach((target) => {
    if (target.getAttribute("data-gk-toggle-value") === element.value) {
      target.classList.remove("gk-disabled");
    } else {
      target.classList.add("gk-disabled");
    }
  });
};

const onInput = (event) => toggleTargets(event.target);

export default () =>
  findAll(document, ".gk-toggle")
    .filter((element) => isUnattached("toggle", element))
    .map((element) => setAttached("toggle", element))
    .forEach((element) => {
      element.addEventListener("input", onInput);
      toggleTargets(element);
    });

import { findAll, isUnattached, setAttached } from "./dom";

const unfold = (e) => {
  const element = e.target.closest(".gk-fold");
  const overlay = element.querySelector(".gk-fold-overlay");

  overlay.addEventListener(
    "transitionend",
    () => {
      overlay.remove();
    },
    { once: true }
  );
  overlay.classList.add("gk-is-hidden");

  element.addEventListener(
    "transitionend",
    () => {
      element.style.maxHeight = "";
    },
    { once: true }
  );
  element.style.maxHeight = element.getAttribute("data-gk-fold-max");
};

const attach = (element) => {
  const overlay = element.querySelector(".gk-fold-overlay");
  const foldAt = element.querySelector(element.getAttribute("data-gk-fold-at"));

  if (foldAt) {
    element.setAttribute("data-gk-fold-max", `${element.offsetHeight}px`);
    element.style.maxHeight = `calc(${foldAt.offsetTop}px + 2.5rem)`;

    overlay.addEventListener("click", unfold);
  } else {
    element.classList.remove("gk-fold");
    overlay.remove();
  }
};

export default () =>
  findAll(document, ".gk-fold")
    .filter((element) => isUnattached("fold", element))
    .map((element) => setAttached("fold", element))
    .forEach(attach);

import { findAll, isUnattached, setAttached } from "./dom";

const unfold = (e) => {
  const element = e.target.closest(".gk-advanced");

  const button = element.querySelector(".gk-advanced-button");
  const content = element.querySelector(".gk-advanced-content");

  button.addEventListener(
    "transitionend",
    () => {
      button.remove();

      content.addEventListener(
        "transitionend",
        () => {
          content.style.maxHeight = "";
          content.classList.add("gk-is-visible");
        },
        { once: true }
      );
      content.style.maxHeight = content.getAttribute("data-gk-advanced-max");
    },
    { once: true }
  );

  button.classList.add("gk-is-hidden");
};

const fold = (content) => {
  if (content.offsetHeight > 0) {
    content.setAttribute("data-gk-advanced-max", `${content.offsetHeight}px`);
    content.style.maxHeight = `0px`;
  } else {
    requestAnimationFrame(() => fold(content));
  }
};

const attach = (element) => {
  const button = element.querySelector(".gk-advanced-button");
  const content = element.querySelector(".gk-advanced-content");

  button.addEventListener("click", unfold);

  fold(content);
};

export default () =>
  findAll(document, ".gk-advanced")
    .filter((element) => isUnattached("advanced", element))
    .map((element) => setAttached("advanced", element))
    .forEach(attach);

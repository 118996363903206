import advanced from "./advanced";
import autofocus from "./autofocus";
import autoload from "./autoload";
import autosubmit from "./autosubmit";
import autoupload from "./autoupload";
import calendar from "./calendar";
import checklink from "./checklink";
import copy from "./copy";
import datepicker from "./datepicker";
import fold from "./fold";
import fullcalendar from "./fullcalendar";
import infinite from "./infinite";
import keyboard from "./keyboard";
import modal from "./modal";
import muscles from "./muscles";
import nav from "./nav";
import preview from "./preview";
import search from "./search";
import select from "./select";
import substitute from "./substitute";
import timepicker from "./timepicker";
import toasts from "./toasts";
import toggle from "./toggle";
import tooltips from "./tooltips";
import validations from "./validations";
import viewer from "./viewer";

import URI from "urijs";

class Cajour {
  constructor() {
    this.autoloadPending = new Set();
  }

  start() {
    document.addEventListener("ajax:before", () => {
      Turbolinks.controller.adapter.progressBar.setValue(0);
      Turbolinks.controller.adapter.progressBar.show();
    });
    document.addEventListener("ajax:error", () => {
      Turbolinks.controller.adapter.progressBar.hide();
      document
        .getElementById("toasts")
        .insertAdjacentHTML(
          "beforeend",
          '<div class="toast toast-error" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><p>Der opstod en desværre fejl &#x1F61F;</p><small>Forsøg venligst igen eller kontakt Cajour support.</small></div></div>'
        );
      toasts();
    });
    document.addEventListener("turbolinks:load", () => this.attach(true));
  }

  attach(turbolinks = false) {
    advanced();
    autofocus(turbolinks);
    autoload(this.autoloadPending);
    autosubmit();
    autoupload();
    calendar();
    checklink();
    copy();
    datepicker();
    fold();
    fullcalendar();
    infinite();
    keyboard();
    modal();
    muscles();
    nav();
    preview();
    search();
    select();
    timepicker();
    toasts();
    toggle();
    tooltips();
    validations();
    viewer();
  }

  update(url, target, contents) {
    const targetElement = document.querySelector(target);
    const previousUrl = targetElement.getAttribute("data-gk-url");

    targetElement.setAttribute("data-gk-url", url);
    targetElement.innerHTML = contents;

    if (
      url &&
      previousUrl &&
      URI(url).normalize().path() != URI(previousUrl).normalize().path()
    ) {
      targetElement.scrollTop = 0;
    }
  }

  completed(url, title, push = true) {
    if (!this.autoloadPending.delete(URI(url).normalize().resource()) && push) {
      [url, title] = substitute(url, title);
      if (title) {
        document.title = `${title} - Cajour`;
      }
      Turbolinks.controller.history.push(url, Turbolinks.uuid());
    }
    Turbolinks.controller.adapter.progressBar.hide();
    this.attach();
  }
}

if (!window.Cajour) {
  window.Cajour = new Cajour();
}

export default window.Cajour;

import { findAll, isUnattached, setAttached } from "./dom";
import flatpickr from "flatpickr";

export default () =>
  findAll(document, "input.gk-timepicker")
    .filter(
      (element) =>
        isUnattached("timepicker", element) &&
        !element.classList.contains("flatpickr-input")
    )
    .map((element) => setAttached("timepicker", element))
    .forEach((element) => {
      const instance = flatpickr(element, {
        enableTime: true,
        noCalendar: true,
        static: true,
        dateFormat: "H:i",
        time_24hr: true,
        minuteIncrement: 15,
        altInput: true,
        altInputClass: element.className,
        altFormat: "H.i",
        onReady: (_, __, instance) => {
          setAttached("timepicker", instance.altInput);
          instance.element.className = "flatpickr-input";

          const wrapper = instance.altInput.closest(".flatpickr-wrapper");
          const inputWrapper = instance.element.closest(
            ".gk-floating-placeholder"
          );
          if (wrapper && inputWrapper) {
            inputWrapper.parentNode.insertBefore(wrapper, inputWrapper);
            inputWrapper.insertBefore(
              instance.altInput,
              inputWrapper.firstChild
            );
            inputWrapper.insertBefore(
              instance.element,
              inputWrapper.firstChild
            );
            wrapper.insertBefore(inputWrapper, wrapper.firstChild);
          }
        },
        onOpen: (_, __, instance) => {
          instance._openedAt = new Date().getTime();
          instance.timeContainer.querySelector(
            ".flatpickr-time-separator"
          ).innerHTML = ".";
        },
        onChange: (_, __, instance) =>
          instance.altInput.dispatchEvent(
            new Event("input", {
              bubbles: true,
              cancelable: true,
            })
          ),
        onValueUpdate: (_, __, instance) =>
          instance.altInput.dispatchEvent(
            new Event("input", {
              bubbles: true,
              cancelable: true,
            })
          ),
      });

      instance._originalClose = instance.close;
      instance.close = () => {
        if (
          instance._openedAt &&
          instance._openedAt > new Date().getTime() - 300
        ) {
          return;
        }
        instance._originalClose();
      };
    });

import { findAll, isUnattached, setAttached } from "./dom";

const onInput = (event) => {
  const form = event.target.closest("form");
  const submit = form && form.querySelector("input[type=submit]");

  if (submit) {
    submit.click();
  } else {
    form.submit();
  }
};

export default (initial) =>
  findAll(document, ".gk-autosubmit")
    .filter((element) => isUnattached("autosubmit", element))
    .map((element) => setAttached("autosubmit", element))
    .forEach((element) => element.addEventListener("input", onInput));

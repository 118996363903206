import { findAll, isUnattached, setAttached } from "./dom";
import toasts from "./toasts";

const onClick = (event) => {
  event.preventDefault();
  navigator.clipboard.writeText(
    document
      .querySelector(event.target.getAttribute("data-gk-copy"))
      .textContent.trim()
  );
  document.querySelector("#toasts").insertAdjacentHTML(
    "beforeend",
    `<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-body">Kopieret til udklipsholder</div>
    </div>`
  );
  toasts();
};

export default () =>
  findAll(document, "[data-gk-copy]")
    .filter((element) => isUnattached("copy", element))
    .map((element) => setAttached("copy", element))
    .forEach((element) => element.addEventListener("click", onClick));

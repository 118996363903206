import { findAll, isUnattached, setAttached } from "./dom";

const onDragEnterOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "copy";

  const container = event.target.closest(".gk-avatar-editable");

  if (findAll(container, ".gk-drag-placeholder").length == 0) {
    const div = document.createElement("div");
    div.classList.add("gk-drag-placeholder");
    container.appendChild(div);
  }
};

const onDragLeave = (event) => {
  event.preventDefault();

  const container = event.target.closest(".gk-avatar-editable");

  findAll(container, ".gk-drag-placeholder").forEach((element) =>
    element.remove()
  );
};

const setPreviewImage = (input) => {
  const container = input.closest(".gk-avatar-editable");

  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = function (e) {
      findAll(container, "img,.gk-avatar-placeholder").forEach((element) =>
        element.remove()
      );
      const img = document.createElement("img");
      img.setAttribute("src", e.target.result);
      container.appendChild(img);
    };
    reader.readAsDataURL(input.files[0]);
  }
};

const onDrop = (event) => {
  event.preventDefault();

  const container = event.target.closest(".gk-avatar-editable");
  const input = container.querySelector("input[type=file]");

  findAll(container, ".gk-drag-placeholder").forEach((element) =>
    element.remove()
  );

  const mimeTypes = ["image/png", "image/gif", "image/jpeg"];

  if (
    event.dataTransfer.files &&
    event.dataTransfer.files[0] &&
    mimeTypes.includes(event.dataTransfer.files[0].type)
  ) {
    const d = new DataTransfer();
    d.items.add(event.dataTransfer.files[0]);
    input.files = d.files;

    input.dispatchEvent(
      new Event("input", {
        bubbles: true,
        cancelable: true,
      })
    );

    setPreviewImage(input);
  }
};

const onInput = (event) => {
  const container = event.target.closest(".gk-avatar-editable");
  const input = container.querySelector("input[type=file]");

  setPreviewImage(input);
};

export default () =>
  findAll(document, ".gk-avatar-editable")
    .filter((element) => isUnattached("preview", element))
    .map((element) => setAttached("preview", element))
    .forEach((element) => {
      element
        .querySelector("input[type=file]")
        .addEventListener("input", onInput);

      element.addEventListener("dragenter", onDragEnterOver);
      element.addEventListener("dragleave", onDragLeave);
      element.addEventListener("dragover", onDragEnterOver);
      element.addEventListener("drop", onDrop);
    });

import { findAll, isUnattached, setAttached } from "./dom";
import { sortBy, uniq } from "lodash";

const fillMusclesList = (svg) => {
  const muscles = uniq(
    sortBy(
      findAll(svg, `path.gk-muscle-selected`).map((path) =>
        path.getAttribute("data-gk-muscle")
      )
    ),
    true
  );

  if (muscles.length > 0) {
    document.getElementById("gk-muscles-list").innerHTML = "";

    muscles.forEach((muscle) => {
      const badge = document.createElement("span");
      badge.classList.add("badge");
      badge.classList.add("me-1");
      badge.style.backgroundColor = svg
        .querySelector(`path.gk-muscle[data-gk-muscle="${muscle}"]`)
        .getAttribute("fill");
      badge.textContent = muscle;

      document.getElementById("gk-muscles-list").appendChild(badge);
    });
  } else {
    document.getElementById(
      "gk-muscles-list"
    ).innerHTML = `<span class="text-muted">Vælg muskler ved at klikke ovenfor&hellip;</span>`;
  }

  const input = document.getElementById("journal_entry_description");

  if (input) {
    if (input.value) {
      const disabledMuscles = findAll(svg, `path.gk-muscle`)
        .filter((path) => !path.classList.contains("gk-muscle-selected"))
        .map((path) => path.getAttribute("data-gk-muscle"));

      disabledMuscles.forEach((muscle) => {
        input.value = input.value.replace(
          new RegExp(
            `(?:\\s*,\\s*${muscle}\\s*(?=,|$)|(,\\s*|^\\s*)${muscle}\\s*,\\s*|^\\s*${muscle}\\s*$)`,
            "i"
          ),
          "$1"
        );
      });
    }

    muscles.forEach((muscle) => {
      if (input.value.length > 0) {
        if (!new RegExp(`\\b${muscle}\\b`, "i").test(input.value)) {
          input.value += input.value.length > 0 ? `, ${muscle}` : muscle;
        }
      } else {
        input.value = muscle;
      }
    });
  }
};

const buildMusclesInputs = (svg) => {
  const muscles = uniq(
    sortBy(
      findAll(svg, `path.gk-muscle-selected`).map((path) =>
        path.getAttribute("data-gk-muscle")
      )
    ),
    true
  );

  findAll(
    document.getElementById("anamnesis_muscle_inputs"),
    "input[type=hidden][name='journal_entry[anamnesis][muscles][]']"
  ).forEach((input) => input.remove());

  muscles.forEach((muscle) => {
    const input = document.createElement("input");
    input.setAttribute("form", "journal_entry-form");
    input.type = "hidden";
    input.name = "journal_entry[anamnesis][muscles][]";
    input.value = muscle;
    document.getElementById("anamnesis_muscle_inputs").appendChild(input);
  });
};

const handleEnter = (svg, muscle) => {
  findAll(svg, `path.gk-muscle[data-gk-muscle="${muscle}"]`).forEach((other) =>
    other.classList.add("gk-muscle-hover")
  );
};

const handleLeave = (svg, muscle) => {
  findAll(svg, `path.gk-muscle[data-gk-muscle="${muscle}"]`).forEach((other) =>
    other.classList.remove("gk-muscle-hover")
  );
};

const handleClick = (svg, muscle) => {
  const others = findAll(svg, `path.gk-muscle[data-gk-muscle="${muscle}"]`);

  if (others.some((other) => other.classList.contains("gk-muscle-selected"))) {
    others.forEach((other) => other.classList.remove("gk-muscle-selected"));
  } else {
    others.forEach((other) => other.classList.add("gk-muscle-selected"));
  }

  fillMusclesList(svg);
  buildMusclesInputs(svg);
};

const addListeners = (path) => {
  const svg = path.closest("svg");
  const muscle = path.getAttribute("data-gk-muscle");

  path.addEventListener("mouseenter", () => handleEnter(svg, muscle));
  path.addEventListener("mouseleave", () => handleLeave(svg, muscle));
  path.addEventListener("click", () => handleClick(svg, muscle));
};

const restoreSelection = (svg) => {
  findAll(
    document.getElementById("anamnesis_muscle_inputs"),
    "input[type=hidden][name='journal_entry[anamnesis][muscles][]']"
  ).forEach((input) => {
    const muscle = input.value;
    findAll(svg, `path.gk-muscle[data-gk-muscle="${muscle}"]`).forEach(
      (other) => other.classList.add("gk-muscle-selected")
    );
  });

  fillMusclesList(svg);
  buildMusclesInputs(svg);
};

export default () =>
  findAll(document, ".gk-muscles")
    .filter((element) => isUnattached("muscles", element))
    .map((element) => setAttached("muscles", element))
    .forEach((element) => {
      restoreSelection(element);
      findAll(element, "path.gk-muscle").forEach(addListeners);
    });

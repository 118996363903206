import { findAll, isUnattached, setAttached } from "./dom";
import * as Rails from "@rails/ujs";

const toggle = (element) => {
  if (element.classList.contains("gk-is-collapsed")) {
    element.classList.remove("gk-is-collapsed");
  } else {
    element.classList.add("gk-is-collapsed");
  }

  Rails.ajax({
    type: "PUT",
    url: element.getAttribute("data-gk-nav-url"),
    data: `preferences[nav_collapsed]=${element.classList.contains(
      "gk-is-collapsed"
    )}`,
  });
};

export default () =>
  findAll(document, ".gk-nav")
    .filter((element) => isUnattached("nav", element))
    .map((element) => setAttached("nav", element))
    .forEach((element) => {
      const toggleButton = element.querySelector(".gk-nav-toggle");

      if (toggleButton) {
        toggleButton.addEventListener("click", () => toggle(element));
      }
    });

import { findAll } from "./dom";
import URI from "urijs";

export default (url, title) => {
  const element = findAll(document, ".gk-meta[data-gk-substitute]").find(
    (element) =>
      URI(url).normalize().path() ===
      URI(element.getAttribute("data-gk-substitute")).normalize().path()
  );

  if (element) {
    return [
      element.getAttribute("data-gk-url"),
      element.getAttribute("data-gk-title"),
    ];
  }

  return [url, title];
};

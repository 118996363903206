import { findAll, isUnattached, setAttached } from "./dom";
import { remote } from "./ajax";
import debounce from "lodash/debounce";
import URI from "urijs";

const request = debounce(remote, 50);

const refreshCalendar = (target) => {
  const url = URI(target.getAttribute("data-gk-calendar-url"));

  if (!url) {
    return;
  }

  request(
    url
      .setQuery(
        document.getElementById("appointment_scheduled_for_date").name,
        document.getElementById("appointment_scheduled_for_date").value
      )
      .setQuery(
        document.getElementById("appointment_scheduled_for_time").name,
        document.getElementById("appointment_scheduled_for_time").value
      )
      .setQuery(
        document.getElementById("appointment_therapist_id").name,
        document.getElementById("appointment_therapist_id").value
      )
      .setQuery(
        document.getElementById("appointment_product_id").name,
        document.getElementById("appointment_product_id").value
      )
      .setQuery(
        document.getElementById("appointment_client_relation_id").name,
        document.getElementById("appointment_client_relation_id").value
      )
      .setQuery(
        document.getElementById("appointment_duration").name,
        document.getElementById("appointment_duration").value
      )
      .toString()
  );
};

const inputHandler = (event) => {
  const form = event.target.closest("form");

  if (form) {
    const target = document.querySelector(
      form.getAttribute("data-gk-calendar")
    );

    if (target) {
      refreshCalendar(target);
    }
  }
};

export default () =>
  findAll(document, "form[data-gk-calendar]")
    .filter((element) => isUnattached("calendar", element))
    .map((element) => setAttached("calendar", element))
    .forEach((form) => {
      const target = document.querySelector(
        form.getAttribute("data-gk-calendar")
      );

      if (target) {
        refreshCalendar(target);
      }

      findAll(form, "input,select").map((input) => {
        input.addEventListener("input", inputHandler);
      });
    });

import { findAll, isUnattached, setAttached } from "./dom";
import { remote } from "./ajax";
import debounce from "lodash/debounce";
import URI from "urijs";

const isMobile = () =>
  document.body.classList.contains("gk-application-mobile");

const isScolledIntoView = (element, parent) => {
  if (isMobile()) {
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  } else {
    return (
      parent.scrollTop <= element.offsetTop &&
      parent.scrollTop + parent.offsetHeight >= element.offsetTop
    );
  }
};

const totalWidth = (element) => {
  const style = getComputedStyle(element);
  return (
    element.offsetWidth +
    parseInt(style.marginLeft) +
    parseInt(style.marginRight)
  );
};

const totalHeight = (element) => {
  const style = getComputedStyle(element);
  return (
    element.offsetHeight +
    parseInt(style.marginTop) +
    parseInt(style.marginBottom)
  );
};

const countVisibleItems = (element, parent) => {
  if (isMobile()) {
    return Math.ceil(
      (element.parentNode.offsetWidth / totalWidth(element)) *
        (window.innerHeight / totalHeight(element))
    );
  } else {
    return Math.ceil(
      (element.parentNode.offsetWidth / totalWidth(element)) *
        (parent.offsetHeight / totalHeight(element))
    );
  }
};

const restoreChild = (parent, element) => {
  const scrollIndex = element.getAttribute("data-gk-infinite-scroll");
  const scrolling = element.getAttribute("data-gk-infinite-scrolling");
  const scope = element.getAttribute("data-gk-infinite-scope");

  element.removeAttribute("data-gk-infinite-scroll");
  element.removeAttribute("data-gk-infinite-is-scrolling");

  if (!isUnattached("infinite-initial", parent) && scrolling == "true") {
    return;
  }

  setAttached("infinite-initial", parent);

  const selector = element.getAttribute("data-gk-infinite-selector");

  if (!scrollIndex || !selector) {
    return;
  }

  const items = findAll(element, selector);

  if (items.length <= scrollIndex) {
    return;
  }

  const scrollElement = items[scrollIndex];

  if (scrollElement && !isScolledIntoView(scrollElement, parent)) {
    element.setAttribute("data-gk-infinite-restoring-scroll", true);

    if (isMobile()) {
      document.documentElement.scrollTop = Math.max(
        0,
        scrollElement.offsetTop - window.innerHeight / 2
      );
    } else {
      parent.scrollTop = Math.max(
        0,
        scrollElement.offsetTop - parent.offsetHeight / 2
      );
    }
  }
};

const scrollChild = (parent, element) => {
  if (element.getAttribute("data-gk-infinite-loading")) {
    return;
  }

  if (element.getAttribute("data-gk-infinite-restoring-scroll")) {
    element.removeAttribute("data-gk-infinite-restoring-scroll");
    return;
  }

  const visibleSentinel = findAll(element, ".gk-infinite-sentinel").find(
    (sentinel) => isScolledIntoView(sentinel, parent)
  );

  if (visibleSentinel) {
    element.setAttribute("data-gk-infinite-loading", "true");

    const scope = element.getAttribute("data-gk-infinite-scope");

    const url = URI(visibleSentinel.getAttribute("data-gk-infinite-url"))
      .setQuery(`${scope}-s`, countVisibleItems(visibleSentinel, parent))
      .toString();

    remote(url);
  }
};

const restoreChildren = (element) =>
  findAll(element, ".gk-infinite").forEach((child) =>
    restoreChild(element, child)
  );

const scrollChildren = (element) =>
  findAll(element, ".gk-infinite").forEach((child) =>
    scrollChild(element, child)
  );

const handler = debounce((restore) => {
  findAll(document, ".gk-infinite-parent")
    .filter((element) => !isUnattached("infinite", element))
    .forEach((element) => {
      if (restore === true) {
        restoreChildren(element);
      }
      scrollChildren(element);
    });
}, 250);

const windowScrollHandler = debounce(handler, 250);

const addWindowListeners = () => {
  window.addEventListener("resize", windowScrollHandler, { passive: true });

  if (isMobile()) {
    document.addEventListener("scroll", windowScrollHandler, {
      passive: true,
    });
    document.addEventListener("touchmove", windowScrollHandler, {
      passive: true,
    });
  }
};

const removeWindowListeners = () => {
  window.removeEventListener("resize", windowScrollHandler, { passive: true });

  if (isMobile()) {
    document.removeEventListener("scroll", windowScrollHandler, {
      passive: true,
    });
    document.removeEventListener("touchmove", windowScrollHandler, {
      passive: true,
    });
  }
};

export default () => {
  findAll(document, ".gk-infinite-parent")
    .filter((element) => isUnattached("infinite", element))
    .map((element) => setAttached("infinite", element))
    .forEach((element) => {
      if (!isMobile()) {
        element.addEventListener("scroll", handler, { passive: true });
      }
    });

  handler(true);

  addWindowListeners();
  document.addEventListener("ajax:before", removeWindowListeners);
  document.addEventListener("turbolinks:before-visit", removeWindowListeners);
};

import { findAll, isUnattached, setAttached } from "./dom";

const onInput = (event) => {
  const form = event.target.closest("form");
  const submit = form && form.querySelector("input[type=submit]");

  if (submit) {
    submit.click();
  }
};

export default () =>
  findAll(document, "form[data-remote][data-gk-autoupload]")
    .filter((element) => isUnattached("autoupload", element))
    .map((element) => setAttached("autoupload", element))
    .forEach((element) =>
      element
        .querySelector("input[type=file]")
        .addEventListener("input", onInput)
    );

import { findAll, isUnattached, setAttached } from "./dom";
import debounce from "lodash/debounce";

const onSearch = (event) =>
  event.target.closest("form").querySelector("input[type=submit]").click();

const onInput = debounce(onSearch, 250);

export default () =>
  findAll(document, "input[type=search]")
    .filter((element) => isUnattached("search", element))
    .map((element) => setAttached("search", element))
    .forEach((input) => {
      input.addEventListener("input", onInput);
      input.addEventListener("search", onSearch);
    });

import { findAll, isUnattached, setAttached } from "./dom";
import flatpickr from "flatpickr";

const Danish = {
  weekdays: {
    shorthand: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
    longhand: [
      "søndag",
      "mandag",
      "tirsdag",
      "onsdag",
      "torsdag",
      "fredag",
      "lørdag",
    ],
  },
  months: {
    shorthand: [
      "jan",
      "feb",
      "mar",
      "apr",
      "maj",
      "jun",
      "jul",
      "aug",
      "sep",
      "okt",
      "nov",
      "dec",
    ],
    longhand: [
      "januar",
      "februar",
      "marts",
      "april",
      "maj",
      "juni",
      "juli",
      "august",
      "september",
      "oktober",
      "november",
      "december",
    ],
  },
  ordinal: function () {
    return ".";
  },
  firstDayOfWeek: 1,
  rangeSeparator: " til ",
  weekAbbreviation: "",
  time_24hr: true,
};

export default () =>
  findAll(document, "input.gk-datepicker")
    .filter(
      (element) =>
        isUnattached("datepicker", element) &&
        !element.classList.contains("flatpickr-input")
    )
    .map((element) => setAttached("datepicker", element))
    .forEach((element) => {
      const instance = flatpickr(element, {
        locale: Danish,
        weekNumbers: true,
        static: true,
        altInput: true,
        altInputClass: element.className,
        altFormat: "j. F Y",
        dateFormat: "Y-m-d",
        onReady: (_, __, instance) => {
          setAttached("datepicker", instance.altInput);
          instance.element.className = "flatpickr-input";

          const wrapper = instance.altInput.closest(".flatpickr-wrapper");
          const inputWrapper = instance.element.closest(
            ".gk-floating-placeholder"
          );
          if (wrapper && inputWrapper) {
            inputWrapper.parentNode.insertBefore(wrapper, inputWrapper);
            inputWrapper.insertBefore(
              instance.altInput,
              inputWrapper.firstChild
            );
            inputWrapper.insertBefore(
              instance.element,
              inputWrapper.firstChild
            );
            wrapper.insertBefore(inputWrapper, wrapper.firstChild);
          }
        },
        onOpen: (_, __, instance) =>
          (instance._openedAt = new Date().getTime()),
        onChange: (_, __, instance) =>
          instance.altInput.dispatchEvent(
            new Event("input", {
              bubbles: true,
              cancelable: true,
            })
          ),
        onValueUpdate: (_, __, instance) =>
          instance.altInput.dispatchEvent(
            new Event("input", {
              bubbles: true,
              cancelable: true,
            })
          ),
      });

      instance._originalClose = instance.close;
      instance.close = () => {
        if (
          instance._openedAt &&
          instance._openedAt > new Date().getTime() - 300
        ) {
          return;
        }
        instance._originalClose();
      };
    });

import { findAll, isUnattached, setAttached } from "./dom";

const getTooltip = (input) =>
  input.parentNode.querySelector(".invalid-tooltip");

const hasTooltip = (input) => !!getTooltip(input);

const removeInfo = (input) => {
  input.classList.remove("is-info");

  const tooltip = input.parentNode.querySelector(".info-tooltip");
  if (tooltip) {
    tooltip.remove();
  }
};

const createTooltip = (input, caption) => {
  const tooltip = document.createElement("div");
  tooltip.classList.add("invalid-tooltip");
  tooltip.innerHTML = caption;
  input.parentNode.appendChild(tooltip);
};

const isFloatingPlaceholder = (input) =>
  input.parentNode.classList.contains("gk-floating-placeholder");

const clearInvalid = (input, isAssociated = false) => {
  input.classList.remove("is-invalid");

  if (isFloatingPlaceholder(input)) {
    input.parentNode.classList.remove("gk-is-invalid");
  }

  if (hasTooltip(input)) {
    getTooltip(input).remove();
  }

  if (!isAssociated && input.getAttribute("data-gk-validates-associated")) {
    findAll(
      document,
      input.getAttribute("data-gk-validates-associated")
    ).forEach((associatedInput) => clearInvalid(associatedInput, true));
  }
};

const clearInvalidHandler = (event) => clearInvalid(event.target);

const addClearValidationListener = (input) =>
  input.addEventListener("input", clearInvalidHandler, { once: true });

const clearAnimationHandler = (event) => {
  if (event.target.classList.contains("gk-floating-placeholder")) {
    event.target.classList.remove("gk-is-invalid");
    event.target.removeEventListener("animationend", clearAnimationHandler);
  }
};

const setInvalid = (input) => {
  input.classList.add("is-invalid");

  removeInfo(input);

  if (isFloatingPlaceholder(input)) {
    input.parentNode.classList.remove("gk-is-invalid");
    input.parentNode.addEventListener("animationend", clearAnimationHandler);
    input.parentNode.classList.add("gk-is-invalid");
  }

  if (!hasTooltip(input)) {
    createTooltip(input, "skal udfyldes");
  }

  addClearValidationListener(input);
};

const isInvalidDate = (input) =>
  input.classList.contains("gk-datepicker") &&
  input.hasAttribute("required") &&
  !input.parentNode.querySelector("input.flatpickr-input").value;

const isInvalidTime = (input) =>
  input.classList.contains("gk-timepicker") &&
  input.hasAttribute("required") &&
  !input.parentNode.querySelector("input.flatpickr-input").value;

const hasInvalidDate = (form) =>
  findAll(form, ".form-control").filter(isInvalidDate).length > 0;

const hasInvalidTime = (form) =>
  findAll(form, ".form-control").filter(isInvalidTime).length > 0;

const displayValidity = (input) =>
  hasTooltip(input) ||
  !input.checkValidity() ||
  isInvalidDate(input) ||
  isInvalidTime(input)
    ? setInvalid(input)
    : clearInvalid(input);

const attachServerSideValidations = () =>
  findAll(document, ".form-control.is-invalid,.form-select.is-invalid").forEach(
    displayValidity
  );

const isFormValid = (form) =>
  findAll(form, ".form-control,.form-select").every((input) =>
    input.checkValidity()
  ) &&
  !hasInvalidDate(form) &&
  !hasInvalidTime(form);

const handleClientSideValidation = (event) => {
  if (event.target.getAttribute("data-gk-skip-validation")) {
    event.target.removeAttribute("data-gk-skip-validation");
    return true;
  }

  if (!isFormValid(event.target)) {
    event.preventDefault();
    event.stopPropagation();
  }

  findAll(event.target, ".form-control,.form-select").forEach(displayValidity);
};

const attachClientSideValidations = () =>
  findAll(document, "form[novalidate]")
    .filter((element) => isUnattached("validations", element))
    .map((element) => setAttached("validations", element))
    .forEach((form) =>
      form.addEventListener("submit", handleClientSideValidation, false)
    );

const handleSkipValidation = (event) => {
  event.target
    .closest("button[data-gk-skip-validation]")
    .form.setAttribute("data-gk-skip-validation", "true");
};

const attachValidationSkippers = () =>
  findAll(document, "button[data-gk-skip-validation]")
    .filter((element) => isUnattached("validations", element))
    .map((element) => setAttached("validations", element))
    .forEach((button) =>
      button.addEventListener("click", handleSkipValidation)
    );

export default () => {
  attachServerSideValidations();
  attachClientSideValidations();
  attachValidationSkippers();
};

import { findAll, isUnattached, setAttached } from "./dom";
import { remote } from "./ajax";

const changeHandler = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const clickHandler = (event) => {
  event.preventDefault();

  const element = event.target.closest(".gk-checklink");
  const checkbox = element.querySelector("input[type=checkbox]");

  const checked = element.getAttribute("data-gk-initial-state") === "checked";
  checkbox.checked = checked;

  if (checked) {
    remote(element.getAttribute("data-gk-checked-url"));
  } else {
    remote(element.getAttribute("data-gk-unchecked-url"));
  }
};

const attach = (element) => {
  element.setAttribute(
    "data-gk-initial-state",
    element.querySelector("input[type=checkbox]").checked
      ? "checked"
      : "unchecked"
  );
  element.addEventListener("click", clickHandler);
  element
    .querySelector("input[type=checkbox]")
    .addEventListener("change", changeHandler);
};

export default () =>
  findAll(document, ".gk-checklink")
    .filter((element) => isUnattached("checklink", element))
    .map((element) => setAttached("checklink", element))
    .forEach(attach);

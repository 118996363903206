import { Modal } from "bootstrap";
import { findAll, isUnattached, setAttached } from "./dom";

const onClick = (event) => {
  event.preventDefault();

  const href = event.target.getAttribute("href");
  const title = event.target.getAttribute("data-gk-viewer-title");
  const type = event.target.getAttribute("data-gk-viewer-type");

  const element = document.createElement("div");
  element.classList.add("modal");
  element.classList.add("gk-viewer-modal");

  element.innerHTML = `
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">${title}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true"></span></button>
        </div>
        <div class="modal-body d-flex">
          <iframe class="flex-grow-1" src="${href}" type="${type}">
        </div>
      </div>
    </div>
  `;

  document.body.appendChild(element);

  const modal = new Modal(element, { show: false });

  const keyHandler = (event) => {
    if (event.key == "Escape") {
      modal.hide();
    }
  };

  element.addEventListener("hidden.bs.modal", function (e) {
    modal.dispose();
    document.removeEventListener("keydown", keyHandler);
    element.remove();
  });

  document.addEventListener("keydown", keyHandler);

  modal.show();
};

export default () =>
  findAll(document, "a[data-gk-viewer]")
    .filter((element) => isUnattached("viewer", element))
    .map((element) => setAttached("viewer", element))
    .forEach((element) => {
      element.addEventListener("click", onClick);
    });

import * as Rails from "@rails/ujs";

const remote = (url) => {
  const dummy = document.createElement("a");
  dummy.setAttribute("href", url);
  dummy.setAttribute("data-remote", "true");

  dummy.addEventListener("ajax:before", () =>
    document.dispatchEvent(new CustomEvent("ajax:before"))
  );
  dummy.addEventListener("ajax:error", () =>
    document.dispatchEvent(new CustomEvent("ajax:error"))
  );

  Rails.handleRemote.apply(dummy, [
    {
      target: dummy,
      preventDefault: () => undefined,
      stopPropagation: () => undefined,
      stopImmediatePropagation: () => undefined,
    },
  ]);
};

export { remote };

import { findAll, isUnattached, setAttached } from "./dom";
import { remote } from "./ajax";
import * as Rails from "@rails/ujs";
import { Calendar } from "@fullcalendar/core";
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import daLocale from '@fullcalendar/core/locales/da';

const changeEvent = (url, info) => {
  Rails.ajax({
    type: "PUT",
    url: url,
    dataType: "application/json",
    data: new URLSearchParams({
      id: info.event.extendedProps.id,
      type: info.event.extendedProps.type,
      start: info.event.startStr,
      end: info.event.endStr,
    }).toString()
  });
};

const attach = (element) => {
  let view = element.dataset.view || "timeGridWeek";

  const calendar = new Calendar(element, {
    height: "100%",
    themeSystem: 'bootstrap5',
    locale: daLocale,
    selectable: true,
    editable: true,
    plugins: [timeGridPlugin, dayGridPlugin, bootstrap5Plugin, interactionPlugin],
    navLinks: true,
    weekNumbers: true,
    weekNumberFormat: { week: 'numeric' },
    timeZone: "local",
    initialView: view,
    allDaySlot: false,
    headerToolbar: {
      left: "prev,next today",
      center: "title",
      right: "dayGridMonth,timeGridWeek,timeGridDay",
    },
    events: element.dataset.eventsUrl,
    eventBackgroundColor: "#355c7d",
    eventResize: changeEvent.bind(null, element.dataset.eventsUrl),
    eventDrop: changeEvent.bind(null, element.dataset.eventsUrl),
    viewClassNames: function(info) {
      if (info.view.type !== view) {
        Rails.ajax({
          type: "PUT",
          url: element.dataset.preferencesUrl,
          data: `preferences[calendar_view]=${info.view.type}`,
        });
      }

      view = info.view.type;
      return [];
    },
    eventClick: function(info) {
      info.jsEvent.preventDefault(); // don't let the browser navigate
      remote(info.event.url);
    },
    dateClick: function(info) {
      remote(element.dataset.newEventUrl + "?scheduled_for=" + encodeURIComponent(info.dateStr));
    },
    select: function(info) {
      remote(element.dataset.newEventUrl + "?scheduled_for=" + encodeURIComponent(info.startStr) + "&ends_at=" + encodeURIComponent(info.endStr));
    }
  });

  calendar.render();

  element.addEventListener("gk:fullcalendar:refresh", () => {
    calendar.refetchEvents();
  });
};

export default () =>
  findAll(document, ".gk-fullcalendar")
    .filter((element) => isUnattached("fullcalendar", element))
    .map((element) => setAttached("fullcalendar", element))
    .forEach(attach);
